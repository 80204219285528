import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Typography, useTheme } from '@mui/material';

// Themed paragraph component
const ThemedParagraph = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Typography
      gutterBottom
      variant="body1"
      component="p"
      lineHeight={2}
      mb={2}
      style={{
        fontFamily:
          'BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        color: theme.palette.mode === 'dark' ? '#B8B8B8' : '#404040',
        paddingLeft: '1px',
        paddingRight: '1px',
        fontSize: '16px',
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
const ThemedList = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Typography
      gutterBottom
      variant="body1"
      component="li"
      mb={2}
      style={{
        fontFamily:
          'BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        color: theme.palette.mode === 'dark' ? '#A8A8A8' : '#404040',
        paddingLeft: '1px',
        paddingRight: '1px',
        fontSize: '16px',
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

const components = {
  h1: ({ children }) => (
    <Typography
      gutterBottom
      variant="h1"
      component="h1"
      mb={3}
      fontWeight={'bold'}
      style={{
        fontFamily:
          'BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      }}
    >
      {children}
    </Typography>
  ),
  h2: ({ children }) => (
    <Typography
      gutterBottom
      fontWeight={'bold'}
      variant="h2"
      component="h2"
      mb={3}
      style={{
        fontFamily:
          'BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        fontSize: '35px',
      }}
    >
      {children}
    </Typography>
  ),
  h3: ({ children }) => (
    <Typography
      gutterBottom
      fontWeight={'bold'}
      variant="h3"
      mb={3}
      component="h3"
      style={{
        fontFamily:
          'BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        fontSize: '27px',
      }}
    >
      {children}
    </Typography>
  ),
  h4: ({ children }) => (
    <Typography
      gutterBottom
      variant="h4"
      mb={3}
      component="h4"
      style={{
        fontFamily:
          'BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      }}
    >
      {children}
    </Typography>
  ),
  p: ThemedParagraph,
  li: ThemedList,

  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <SyntaxHighlighter
        style={atomDark}
        language={match[1]}
        PreTag="div"
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};

export { components };
