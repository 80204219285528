import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTags = ({
  title,
  description,
  image,
  url,
  type = 'article',
  author = 'Kingsley Okon',
  keywords,
  date,
  category,
}) => {
  const DEFAULT_IMAGE =
    'https://images.unsplash.com/photo-1599081595476-75608b796d52?w=840&h=500&fit=crop&q=80';
  const finalImage = image || DEFAULT_IMAGE;
  const finalUrl =
    url || (typeof window !== 'undefined' ? window.location.href : '');

  return (
    <Helmet prioritizeSeoTags>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords || ''} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={finalUrl} />
      <meta property="og:image" content={finalImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="KOkon.app" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={finalImage} />

      {/* Article Specific Tags */}
      {date && <meta property="article:published_time" content={date} />}
      <meta property="article:author" content={author} />
      {category && <meta property="article:section" content={category} />}
      {keywords && <meta property="article:tag" content={keywords} />}
    </Helmet>
  );
};

export default MetaTags;
