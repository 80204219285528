import React from 'react';
import './footer.scss';
import { LinkedIn, GitHub, Mail } from '@material-ui/icons';

function Footer() {
  return (
    <div className="footer">
      <div className="content">
        <span className="credit">
          © {new Date().getFullYear()}{' '}
          <span className="name">Kingsley Okon</span>
        </span>
        <div className="social-links">
          <a
            href="https://www.linkedin.com/in/kingsley-okon-190164221/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <LinkedIn />
          </a>
          <a
            href="https://github.com/kingsley9"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="GitHub"
          >
            <GitHub />
          </a>
          <a href="mailto:kingsleyokon09@gmail.com" aria-label="Email">
            <Mail />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
